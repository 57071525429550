import { graphql } from 'gatsby';
import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { Renderer } from '../../components/common/renderer';
import { CONTENT_MAPPING } from '../../components/content/mapping';
import { Sitemeta } from '../../components/core/sitemeta';
import { DepartmentHeading } from '../../components/headings/department';
import { entryType, responseType } from '../../types';

export const query = graphql`
  query DepartmentPage(
    $departmentId: [CraftGQL_QueryArgument]!
  ) {
    response: craftgql {
      department: entry(id: $departmentId) {
        title
        ...DepartmentContentsFragment

        ...on CraftGQL_departments_department_Entry {
          color: departmentColor
          slogan: departmentSlogan

          metadata: pageMetadata {
            ...MetadataFragment
          }
        }
      }
    }
  }
`;

const DepartmentPage = ({ data }) => {
  const { department } = data.response;
  const {
    title, slogan, contents, metadata: [metadata],
  } = department;

  return (
    <>
      <Sitemeta
        title={title}
        {...metadata}
        color={department.color}
      />
      <DepartmentHeading title={title} slogan={slogan} />
      <div id="contents">
        <Renderer contents={contents} mapping={CONTENT_MAPPING} />
      </div>
    </>
  );
};

DepartmentPage.propTypes = {
  ...responseType({
    department: entryType({
      title: string.isRequired,
      color: string.isRequired,
      contents: arrayOf(shape({
        typename: string,
      })).isRequired,
    }).isRequired,
  }).isRequired,
};

export default DepartmentPage;
